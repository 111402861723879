p, li {
  font-family: 'ITC Avant Garde Gothic Pro', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.3em;
}

h1, h2, h3, h4, h5 {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

h5 {
  font-size: 20px;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Pro';
  src: url('/public/itc-avant-garde-gothic-pro-medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/helvetica-neue-2.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #000000;
  background: white;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border: solid #dddddd 1px;
}

.ReactModalPortal {
  position: relative;
  z-index: 10;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.range-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 16px;
}

#range-tooltip {
  --left: -6px;
  position: absolute;
  top: -35px;
  left: var(--left);
  background: black;
  color: white;
  text-align: center;
  width: 28px;
  padding: 0px 5px;
  border-radius: 4px;
}

#range-tooltip:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.range-slider.export {
  height: 15px;
  background: transparent;
  border-radius: 16px;
  border: 1px solid black;
}

.range-slider.export::after {
  content: '';
  height: 14px;
  width: 14px;
  position: absolute;
  top: 3px;
  left: var(--left);
  border-radius: 50%;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid black;
}

.range-slider::-webkit-slider-runnable-track {
  height: 15px;
  background: transparent;
  border-radius: 16px;
  border: 1px solid black;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid black;
}

.range-slider::-moz-range-track {
  height: 15px;
  background: transparent;
  border-radius: 16px;
  border: 1px solid black;
}

.range-slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid black;
}

.input {
  max-width: 100% !important;
}

.button {
  @apply btn text-black hover:text-white btn-outline btn-rounded;
}

.auth-button {
  @apply btn bg-black hover:bg-black text-white btn-outline btn-rounded;
}

.sand-background {
  background-image: url('../src/img/background_sand_ruis.jpeg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.table td {
  color: black !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  @apply text-black bg-black;
}
